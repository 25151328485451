/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import oauth from "axios-oauth-client";
import { useInterval } from "../utils/hooks";
import provider from "../providers/config";
import * as auth from "../providers/auth.provider";
import createNotification from "../utils/notification";

const AuthContext = createContext({
  signed: null,
  user: null,
  manager: null,
  store: null,
  signIn: null,
  signOut: null,
  updateUser: null,
  updateStore: null,
  checkIfTokenExpired: null,
  baseURL: null
});

// const baseURLManager = "http://localhost:8000";
// const clientId = "qyzqVSsigt6fOA27EWEIIfmt1ZVKTpAeAAKlvd3e";
// const clientSecret = "OtS4OWcdpdulgyHtNqiT7OudhMXaG7e2KDPwm5ZmRWDyotCeUXgUgiqXN3J4r1C3yTWFJpK18JWoTzvipOREziarT3BRFh1EuGX7FttAa7jiEex9tKvu0fz3V6WiPb3U";
const baseURLManager = "https://shop-manager.devari.com.br";
const clientId = "ckAspaGDpaYMQaO96KsHGA6yA4NnHtBH5iur0LhH";
const clientSecret = "6FL7WMSFM9qLTRzEUBf8dfmbJckIkQocH71QwQmPTkHdpIYRnrgmIRdO0QovstqR9nHFo4fmjb2pIxyquxOUaGVfnHgnTjZ15U3wrvsCcvtAl39esuFl2p2daFq4VCSx";

async function getManagerCredentials(payload) {
  const getAuthorizationCode = oauth.client(axios.create(), {
    url: `${baseURLManager}/o/token/`,
    grant_type: "password",
    client_id: clientId,
    client_secret: clientSecret,
    username: payload.username,
    password: payload.password,
  });

  try {
    const managerCredentials = await getAuthorizationCode();
    return managerCredentials;
  } catch (err) {
    return createNotification("error", "Erro", "Email ou Senha inválido(s)");
  }
}

async function refreshToken(token) {
  const getRefreshedToken = oauth.client(axios.create(), {
    url: `${baseURLManager}/o/token/`,
    grant_type: "refresh_token",
    client_id: clientId,
    client_secret: clientSecret,
    refresh_token: token,
  });

  try {
    const managerCredentials = await getRefreshedToken();
    return managerCredentials;
  } catch (err) {
    return createNotification(
      "error",
      "Erro",
      "Ocorreu um erro ao tentar renovar o token de acesso"
    );
  }
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [manager, setManager] = useState(null);
  const [store, setStore] = useState(null);
  const [checkToken, setCheckToken] = useState(false);
  const [loading, setLoading] = useState(true);
  const [baseURL, setBaseURL] = useState("");

  useInterval(
    () => {
      checkIfTokenExpired(manager);
    },
    checkToken ? 60000 : null
  );

  async function checkIfTokenExpired(manager) {
    if (!manager) {
      return;
    }

    try {
      await axios.get(
        `${baseURLManager}/api/v1/authentication?access_token=${manager.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${manager.access_token}`,
          },
        }
      );
    } catch (err) {
      try {
        const refreshedToken = await refreshToken(manager.refresh_token);

        return setManager({
          ...manager,
          access_token: refreshedToken.access_token,
          refresh_token: refreshedToken.refresh_token,
        });
      } catch (err) {
        signOut();
      }
    }
  }

  useEffect(() => {
    if (user !== null) {
      localStorage.setItem("@ecom:admin", JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    if (store !== null) {
      localStorage.setItem("@ecom:store", JSON.stringify(store));
    }
  }, [store]);

  useEffect(() => {
    if (manager !== null) {
      localStorage.setItem("@ecom:storeManager", JSON.stringify(manager));
    }
  }, [manager]);

  useEffect(() => {
    async function loadStoragedData() {
      const storagedUser = localStorage.getItem("@ecom:admin");
      const storagedStore = localStorage.getItem("@ecom:store");
      const storagedStoreManager = localStorage.getItem("@ecom:storeManager");

      if (storagedUser && storagedStore && storagedStoreManager) {
        setUser(JSON.parse(storagedUser));
        setStore(JSON.parse(storagedStore));
        setManager(JSON.parse(storagedStoreManager));
        setCheckToken(true);

        provider.defaults.baseURL = `${
          JSON.parse(storagedStoreManager).store.url
        }/api/v1`;
        setBaseURL(`${
          JSON.parse(storagedStoreManager).store.url
        }/api/v1`);

        await new Promise((resolve, reject) => {
          let link =
            document.querySelector('link[rel="shortcut icon"]') ||
            document.querySelector('link[rel="icon"]');

          if (!link) {
            link = document.createElement("link");
            link.id = "favicon";
            link.rel = "shortcut icon";
            document.head.appendChild(link);
          }

          link.href = JSON.parse(storagedStore).favicon;

          resolve();
        });
      }

      setLoading(false);
    }

    loadStoragedData();
  }, []);

  async function signIn(payload) {
    const credentials = await getManagerCredentials(payload);

    if (!credentials) {
      return;
    }

    const managerStore = await axios.get(
      `${baseURLManager}/api/v1/authentication?access_token=${credentials.access_token}`,
      {
        headers: {
          Authorization: `Bearer ${credentials.access_token}`,
        },
      }
    );

    const managerInformations = managerStore.data;

    localStorage.setItem(
      "@ecom:storeManager",
      JSON.stringify({
        ...managerInformations,
        access_token: credentials.access_token,
        refresh_token: credentials.refresh_token,
      })
    );

    setManager({
      ...managerInformations,
      access_token: credentials.access_token,
      refresh_token: credentials.refresh_token,
    });
    provider.defaults.baseURL = `${managerInformations.store.url}/api/v1`;
    setBaseURL(`${managerInformations.store.url}/api/v1`);

    const authResponse = await auth.signIn(payload);
    const storeResponse = await auth.getStore();

    if (authResponse.error || storeResponse.error) {
      return createNotification("error", "Erro", "Erro de credenciais");
    }

    await new Promise((resolve, reject) => {
      let link =
        document.querySelector('link[rel="shortcut icon"]') ||
        document.querySelector('link[rel="icon"]');

      if (!link) {
        link = document.createElement("link");
        link.id = "favicon";
        link.rel = "shortcut icon";
        document.head.appendChild(link);
      }

      link.href = storeResponse.favicon;

      resolve();
    });

    setStore(storeResponse);
    setCheckToken(true);
    return setUser(authResponse);
  }

  async function signOut() {
    localStorage.clear();
    setUser(null);
    setStore(null);
    setCheckToken(false);
    setBaseURL("");
    provider.defaults.baseURL = null;

    await new Promise((resolve, reject) => {
      let link =
        document.querySelector('link[rel="shortcut icon"]') ||
        document.querySelector('link[rel="icon"]');

      if (!link) {
        link = document.createElement("link");
        link.id = "favicon";
        link.rel = "shortcut icon";
        document.head.appendChild(link);
      }

      link.href = "./favicon.ico";

      resolve();
    });
  }

  async function updateUser(payload) {
    const managerResponse = await auth.updateManager(
      manager.user,
      payload,
      manager.access_token
    );

    if (managerResponse.error) {
      return createNotification(
        "error",
        "Erro",
        "Ocorreu um erro ao tentar atualizar seu perfil no gerenciador"
      );
    }

    setUser({ ...user, email: payload.email, username: payload.email });
    setManager({
      ...manager,
      store: {
        ...manager.store,
        email: payload.email,
      },
    });

    return createNotification(
      "success",
      "Sucesso",
      "Perfil atualizado com sucesso"
    );
  }

  async function updateStore(payload) {
    const response = await auth.updateStore(user.token, payload);

    if (response.error) {
      return createNotification(
        "error",
        "Erro",
        "Ocorreu um erro ao tentar atualizar as informações da loja"
      );
    }

    setStore(response);
    return createNotification(
      "success",
      "Sucesso",
      "Informações da loja alteradas com sucesso"
    );
  }

  function handleChangeStore(payload) {
    setStore(payload);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user && !!store,
        user,
        store,
        manager,
        signIn,
        signOut,
        updateUser,
        updateStore,
        handleChangeStore,
        checkIfTokenExpired,
        baseURL
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
