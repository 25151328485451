import provider from "./config";
import axios from "axios";

export async function signIn(payload) {
  try {
    const response = await provider.post("/admin/authentication/", payload);
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function updateManager(id, payload, token) {
  try {
    const response = await axios.patch(
      `https://shop-manager.devari.com.br/api/v1/admin/user/${id}/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function updateProfile(id, payload, token) {
  try {
    const response = await provider.patch(`/admin/user/${id}/`, payload, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    if (Array.isArray(err.response.data) && err.response.data.length > 0) {
      return { error: true, errorMessage: err.response.data[0] };
    }
    return { error: true, message: err.message };
  }
}

export async function getStore() {
  try {
    const response = await provider.get("/store/1");
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function updateStore(token, payload) {
  try {
    const response = await provider.patch("/store/1/", payload, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}
