import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { useAuth } from "./contexts/auth.context";
import ContextProvider from "./contexts";
import "./App.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

const Login = React.lazy(() => import("./views/Pages/Login"));

function UnAuthRoute({ children, ...rest }) {
  const { signed } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        signed ? (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

const Routes = () => {
  const { signed } = useAuth();

  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <UnAuthRoute exact path="/login" name="Login Page">
            <Login />
          </UnAuthRoute>
          <Route
            path="/"
            name="Home"
            render={(props) => {
              return signed ? (
                <DefaultLayout {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: props.location },
                  }}
                />
              );
            }}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

const App = () => {
  return (
    <ContextProvider>
      <Routes />
      <NotificationContainer />
    </ContextProvider>
  );
};

export default App;
